import React, { Component } from "react";
import { environmentMode } from "../components/lib/ad-manage-api-lib";
import { Grid, Header, Image } from "semantic-ui-react";
import SignupForm from "../components/forms/signup-form/signup-form";
import { formatQuery } from "../controllers/utilities/string-utils";
import html2canvas from "html2canvas";
import logo from "../images/main/logo.png";
import box1 from "../images/login/infobox2.png"
import box2 from "../images/login/infobox3.png"
import htopbg from "../images/main/main_bg2.png";
import hlogo from "../images/main/hlogo.png";
import { navigate } from "gatsby";

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            expireTimer: null
        };
    }

    componentDidMount() {
        // window.addEventListener('resize', this.getDirection);

        this.state.expireTimer = setTimeout(() => {
            clearInterval(this.state.expireTimer);
            if (this.state.ready) {
                const newCanvas = document.createElement("canvas");
                const element = document.querySelector('#QR');

                const dom_width = parseInt(window.getComputedStyle(element).width);
                const dom_height = parseInt(window.getComputedStyle(element).height);
                console.log(dom_width)
                console.log(dom_height)
                //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
                const scaleBy = DPR();
                newCanvas.width = dom_width * scaleBy;
                newCanvas.height = dom_height * scaleBy;
                newCanvas.style.width = newCanvas.width + "px";
                newCanvas.style.height = newCanvas.height + "px";
                const context = newCanvas.getContext("2d");
                context.scale(1, 1);

                html2canvas(element, { canvas: newCanvas }).then((canvas) => {
                    const imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
                    const base64ToBlob = (code) => {
                        let parts = code.split(';base64,');
                        let contentType = parts[0].split(':')[1];
                        let raw = window.atob(parts[1]);
                        let rawLength = raw.length;

                        let uInt8Array = new Uint8Array(rawLength);

                        for (let i = 0; i < rawLength; ++i) {
                            uInt8Array[i] = raw.charCodeAt(i);
                        }
                        return new Blob([uInt8Array], { type: contentType });
                    };
                    const blob = base64ToBlob(imgUri);
                    // window.location.href = imgUri; // 下载图片
                    // 利用createObjectURL，模拟文件下载
                    const fileName = dateFormat(Date.now(), "yyyyMMddhhmmss") + '.png';

                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        const blobURL = window.URL.createObjectURL(blob)
                        const vlink = document.createElement('a');
                        vlink.style.display = 'none';
                        vlink.href = blobURL;
                        vlink.setAttribute('download', fileName);

                        if (typeof vlink.download === 'undefined') {
                            vlink.setAttribute('target', '_blank');
                        }

                        document.body.appendChild(vlink);

                        const evt = document.createEvent("MouseEvents");
                        evt.initEvent("click", false, false);
                        vlink.dispatchEvent(evt);

                        document.body.removeChild(vlink);

                        window.URL.revokeObjectURL(blobURL);

                        console.log(blobURL);
                    }

                });

            }
        }, 2000);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);
        if (this.state.expireTimer !== null) {
            clearInterval(this.state.expireTimer);
        }
    }

    getDirection = () => {
        // this.setState({ state: this.state });
        const mode = detectOrient();
        if (mode === "PORTRAIT") {
            if (typeof window !== `undefined`) {
                navigate(`/downloadimage/${this.props.location.search}`);
            }
        }
    }

    render() {
        const { data } = formatQuery(this.props.location.search);
        let username = "";
        let password = "";
        if (data) {
            const datas = data.split(',');
            username = datas[0];
            password = datas[1];
        }
        const mode = detectOrient();
        if (mode === "PORTRAIT") {
            if (typeof window !== `undefined`) {
                navigate(`/downloadimage/${this.props.location.search}`);
            }
        } else {
            if (!this.state.ready) {
                this.setState({
                    ready: true,
                });
            }
        }
        return (<>
            {(this.state.ready) && (<div id="QR">
                <Grid textAlign='center' style={{
                    height: "100vh",
                    padding: "3vh",
                    margin: "0",
                    backgroundImage: `url(${htopbg})`, backgroundSize: '100% 100%'
                }} verticalAlign='middle'>
                    <div>
                        <Image style={{
                            marginTop: "7vh",
                            height: "2em",
                        }}
                            centered src={hlogo} />
                        <div style={{
                            marginTop: 25,
                            maxWidth: 360,
                            height: "17em",
                            background: "transparent",
                            backgroundImage: `url(${box2})`, backgroundSize: '100% 100%'
                        }}>
                            <div style={{
                                padding: "8px", fontSize: "14px"
                            }}>
                                <Header style={{ fontSize: "16px" }}>&nbsp;</Header>
                                <Header style={{ fontSize: "16px" }}>請截圖本頁面</Header>
                                <p style={{ fontWeight: "bold" }}>
                                    用戶名: {username}
                                </p>
                                <p style={{ fontWeight: "bold" }}>
                                    密碼: {password}
                                </p>
                                <p>
                                    請注意：為免遺失帳號，謹請截圖保存。
                                </p>
                            </div>
                        </div>
                    </div>


                </Grid>
            </div>)}
        </>);
    }
}

function DPR() {
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
    }
    return 1;
}

function dateFormat(time, fmt) { // author: meizz
    const date = new Date(time);
    // console.log(date);
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}